<template>
  <div class="conclient">
    <component :is="componentId"
               @moreinfo='moreinfo'
               :id="custorid"
               @back="componentId='list'"
               @setparams='setparams'
               :params='params'
               @getlist='getlist'
               :List='Getlist'></component>
  </div>
</template>

<script>
import list from './pages/index' // 列表页面
import ddel from './pages/added/index' // 新增或者删除客户
export default {
  components: {
    list, ddel
  },
  data () {
    return {
      componentId: 'list', // 默认进来为列表页
      custorid: '',
      params: {
        cad: '',
        active: '',
        cuactive: ''
      },
      Getlist: null
    }
  },
  methods: {
    moreinfo (id) {
      this.componentId = 'ddel'
      this.custorid = id
    },
    // 查询条件
    setparams (name, value) {
      this.params[name] = value
    },
    getlist (data) {
      this.Getlist = data
    }
  }
}
</script>

<style lang='stylus'>
.conclient
  width 100%
  height 100%
</style>
