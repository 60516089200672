<template>
  <ul class="check_box">
    <li v-show="checked"></li>
    <li v-show="checked"></li>
    <input type="checkBox"
           :checked="checked"
           @change="$emit('change',$event.target.checked)" />
  </ul>
</template>
<script>
export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: Boolean
  }
}
</script>
<style lang="stylus" scoped>
.check_box
  width 14px
  height 14px
  border 1px solid #999999
  position relative
  li:nth-child(1)
    width 65%
    transform rotate(45deg)
    top 6px
  li:nth-child(2)
    width 90%
    transform rotate(-56deg)
    left 4px
    top 4px
  li
    height 2px
    background #5DCD43
    position absolute
    left 0px
    top 0px
    margin 0px
  input
    display inline-block
    width 100%
    height 100%
    position absolute
    top 0px
    left 0px
    opacity 0
    z-index 2
</style>
