var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "conclient" },
    [
      _c(_vm.componentId, {
        tag: "component",
        attrs: { id: _vm.custorid, params: _vm.params, List: _vm.Getlist },
        on: {
          moreinfo: _vm.moreinfo,
          back: function ($event) {
            _vm.componentId = "list"
          },
          setparams: _vm.setparams,
          getlist: _vm.getlist,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }