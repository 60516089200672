var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "100%", height: "100%" } },
    [
      _vm.getssnum === 1
        ? _c(
            "fb-page",
            { ref: "ctn", staticStyle: { background: "#ffffff" } },
            [
              _c(
                "fb-header",
                {
                  attrs: {
                    slot: "header",
                    title: "我的客户(" + this.title + ")",
                    customer: true,
                    fixed: "",
                  },
                  slot: "header",
                },
                [
                  _c("fb-button", {
                    attrs: {
                      slot: "left",
                      icon: "back",
                      size: "small",
                      type: "primary",
                    },
                    on: { click: _vm.handleGoBack },
                    slot: "left",
                  }),
                  _c("template", { slot: "customer" }, [
                    _c("i", {
                      staticClass: "iconfont icon-add header_icon",
                      on: { click: _vm.added },
                    }),
                  ]),
                ],
                2
              ),
              _c(
                "ul",
                {
                  staticClass: "screen",
                  style: {
                    "border-bottom":
                      _vm.caseli || _vm.custype || _vm.cusroad
                        ? "1px solid #ccc"
                        : "",
                  },
                },
                [
                  _c(
                    "li",
                    {
                      style: { color: _vm.caseli ? "#4B9EFB" : "#333" },
                      on: {
                        click: function ($event) {
                          return _vm.qiehuan(1)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v("案件")]),
                      _c("i", {
                        staticClass: "iconfont icon-daka-copy",
                        style: { color: _vm.caseli ? "#4B9EFB" : "#ccc" },
                      }),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      style: { color: _vm.custype ? "#4B9EFB" : "#333" },
                      on: {
                        click: function ($event) {
                          return _vm.qiehuan(2)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v("客户类型")]),
                      _c("i", {
                        staticClass: "iconfont icon-daka-copy",
                        style: { color: _vm.custype ? "#4B9EFB" : "#ccc" },
                      }),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      style: { color: _vm.cusroad ? "#4B9EFB" : "#333" },
                      on: {
                        click: function ($event) {
                          return _vm.qiehuan(3)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v("客户来源")]),
                      _c("i", {
                        staticClass: "iconfont icon-daka-copy",
                        style: { color: _vm.cusroad ? "#4B9EFB" : "#ccc" },
                      }),
                    ]
                  ),
                ]
              ),
              _vm.caseli || _vm.custype || _vm.cusroad
                ? _c(
                    "div",
                    {
                      staticClass: "polit",
                      on: {
                        scroll: function ($event) {
                          return _vm.getload($event)
                        },
                      },
                    },
                    [
                      _vm.caseli
                        ? _c(
                            "ul",
                            { staticClass: "custype" },
                            _vm._l(_vm.getlist, function (item, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  class: [
                                    item.caseId === _vm.cad ? "addclass" : "",
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.caseid(
                                        index,
                                        item.caseId,
                                        item.title
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.title) + " ")]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm.custype
                        ? _c("ul", { staticClass: "custype" }, [
                            _c(
                              "li",
                              {
                                class: [_vm.active === 0 ? "addclass" : ""],
                                on: {
                                  click: function ($event) {
                                    _vm.active = 0
                                    _vm.getscreen()
                                    _vm.caseli = false
                                    _vm.custype = false
                                    _vm.cusroad = false
                                  },
                                },
                              },
                              [_vm._v(" 自然人 ")]
                            ),
                            _c(
                              "li",
                              {
                                class: [_vm.active === 1 ? "addclass" : ""],
                                on: {
                                  click: function ($event) {
                                    _vm.active = 1
                                    _vm.getscreen()
                                    _vm.caseli = false
                                    _vm.custype = false
                                    _vm.cusroad = false
                                  },
                                },
                              },
                              [_vm._v(" 法人 ")]
                            ),
                            _c(
                              "li",
                              {
                                class: [_vm.active === 2 ? "addclass" : ""],
                                on: {
                                  click: function ($event) {
                                    _vm.active = 3
                                    _vm.getscreen()
                                    _vm.caseli = false
                                    _vm.custype = false
                                    _vm.cusroad = false
                                  },
                                },
                              },
                              [_vm._v(" 非法人 ")]
                            ),
                          ])
                        : _vm._e(),
                      _vm.cusroad
                        ? _c("ul", { staticClass: "custype" }, [
                            _c(
                              "li",
                              {
                                class: [_vm.cuactive === 0 ? "addclass" : ""],
                                on: {
                                  click: function ($event) {
                                    _vm.cuactive = 0
                                    _vm.getscreen()
                                    _vm.caseli = false
                                    _vm.custype = false
                                    _vm.cusroad = false
                                  },
                                },
                              },
                              [_vm._v(" 未知 ")]
                            ),
                            _c(
                              "li",
                              {
                                class: [_vm.cuactive === 1 ? "addclass" : ""],
                                on: {
                                  click: function ($event) {
                                    _vm.cuactive = 1
                                    _vm.getscreen()
                                    _vm.caseli = false
                                    _vm.custype = false
                                    _vm.cusroad = false
                                  },
                                },
                              },
                              [_vm._v(" 上门服务 ")]
                            ),
                            _c(
                              "li",
                              {
                                class: [_vm.cuactive === 2 ? "addclass" : ""],
                                on: {
                                  click: function ($event) {
                                    _vm.cuactive = 2
                                    _vm.getscreen()
                                    _vm.caseli = false
                                    _vm.custype = false
                                    _vm.cusroad = false
                                  },
                                },
                              },
                              [_vm._v(" 介绍 ")]
                            ),
                            _c(
                              "li",
                              {
                                class: [_vm.cuactive === 3 ? "addclass" : ""],
                                on: {
                                  click: function ($event) {
                                    _vm.cuactive = 3
                                    _vm.getscreen()
                                    _vm.caseli = false
                                    _vm.custype = false
                                    _vm.cusroad = false
                                  },
                                },
                              },
                              [_vm._v(" 网络 ")]
                            ),
                            _c(
                              "li",
                              {
                                class: [_vm.cuactive === 4 ? "addclass" : ""],
                                on: {
                                  click: function ($event) {
                                    _vm.cuactive = 4
                                    _vm.getscreen()
                                    _vm.caseli = false
                                    _vm.custype = false
                                    _vm.cusroad = false
                                  },
                                },
                              },
                              [_vm._v(" 顾问单位 ")]
                            ),
                            _c(
                              "li",
                              {
                                class: [_vm.cuactive === 5 ? "addclass" : ""],
                                on: {
                                  click: function ($event) {
                                    _vm.cuactive = 5
                                    _vm.getscreen()
                                    _vm.caseli = false
                                    _vm.custype = false
                                    _vm.cusroad = false
                                  },
                                },
                              },
                              [_vm._v(" 电话 ")]
                            ),
                            _c(
                              "li",
                              {
                                class: [_vm.cuactive === 6 ? "addclass" : ""],
                                on: {
                                  click: function ($event) {
                                    _vm.cuactive = 6
                                    _vm.getscreen()
                                    _vm.caseli = false
                                    _vm.custype = false
                                    _vm.cusroad = false
                                  },
                                },
                              },
                              [_vm._v(" 其他 ")]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              !(_vm.caseli || _vm.cusroad || _vm.custype)
                ? _c(
                    "div",
                    { staticClass: "customer" },
                    [
                      _vm._l(_vm.customer, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "customer_name",
                            attrs: { div: "index" },
                          },
                          [
                            item.categoryEnum == "NATURAL_PERSON"
                              ? _c("ul", { staticClass: "name" }, [
                                  _c("li", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "person",
                                        on: {
                                          click: function ($event) {
                                            return _vm.modify(
                                              item.customerId,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.customerName))]
                                    ),
                                    _c("span", [_vm._v(_vm._s(item.sex))]),
                                    item.age
                                      ? _c("span", [
                                          _vm._v(_vm._s(item.age) + "岁"),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _c("li", { staticClass: "phone" }, [
                                    item.phone
                                      ? _c("i", {
                                          staticClass: "iconfont icon-dianhua1",
                                          on: {
                                            click: function ($event) {
                                              return _vm.PhoneDian(item.phone)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _c("span", [_vm._v(_vm._s(item.phone))]),
                                  ]),
                                  _c("i", {
                                    staticClass: "iconfont icon-gengduo",
                                    style: {
                                      transform: _vm.customer[index].showflag
                                        ? "rotate(90deg)"
                                        : "rotate(0deg)",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.customner(index)
                                      },
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            item.categoryEnum != "NATURAL_PERSON"
                              ? _c("ul", { staticClass: "company" }, [
                                  _c("li", [
                                    _c("i", {
                                      staticClass: "iconfont icon-qiye",
                                      on: {
                                        click: function ($event) {
                                          return _vm.sinformation(
                                            item.customerName
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.modify(item.customerId)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.customerName))]
                                    ),
                                    _c("i", {
                                      staticClass: "iconfont icon-gengduo",
                                      style: {
                                        transform: _vm.customer[index].showflag
                                          ? "rotate(90deg)"
                                          : "rotate(0deg)",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.customner(index)
                                        },
                                      },
                                    }),
                                  ]),
                                  !(!item.manager && !item.position)
                                    ? _c("li", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.categoryEnum ==
                                                "LEGAL_ENTITY"
                                                ? "法定"
                                                : "负责"
                                            ) + "代表人："
                                          ),
                                        ]),
                                        item.manager
                                          ? _c("span", [
                                              _vm._v(_vm._s(item.manager)),
                                            ])
                                          : _vm._e(),
                                        item.position
                                          ? _c("span", [
                                              _vm._v(_vm._s(item.position)),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            !_vm.customer[index].showflag
                              ? _c(
                                  "ul",
                                  { staticClass: "case" },
                                  _vm._l(
                                    item.caseAttrList
                                      ? item.caseAttrList.slice(0, 3)
                                      : "",
                                    function (itemlist, indexlist) {
                                      return _c(
                                        "li",
                                        {
                                          key: indexlist,
                                          attrs: { li: indexlist },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                itemlist.procedureSubjectShort
                                              )
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(_vm._s(itemlist.caseName)),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm.list
                              ? _c("ul", { staticClass: "care" }, [
                                  _c("li", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#4b9efb" } },
                                      [_vm._v("原")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        "张三诉南京华为信息科技有限公司劳动纠纷案"
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "box" },
                                      [
                                        _c("check-box", {
                                          model: {
                                            value: _vm.selected,
                                            callback: function ($$v) {
                                              _vm.selected = $$v
                                            },
                                            expression: "selected",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("li", [
                                    _c("span", [_vm._v("上诉期：")]),
                                    _c("span", [_vm._v("2018.2.12-2018.2.27")]),
                                  ]),
                                  _c("li", [_vm._v("（距离结束日期还剩1天）")]),
                                  _c("li", { staticClass: "written" }, [
                                    _c("textarea", {
                                      attrs: {
                                        type: "text",
                                        placeholder:
                                          "在此处添加其他文字内容，我们将把上面的内容，加上此处的文字，以短信的形式发送给该客户。",
                                      },
                                    }),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.customer[index].showflag
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "background-color": "#ffffff",
                                      display: "inline-block",
                                      width: "100%",
                                    },
                                  },
                                  _vm._l(
                                    item.caseAttrList,
                                    function (itemcase, indexcase) {
                                      return _c(
                                        "ul",
                                        {
                                          key: indexcase,
                                          staticClass: "open",
                                          attrs: { ul: indexcase },
                                        },
                                        [
                                          _c(
                                            "li",
                                            {
                                              staticClass: "open_case",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.caselist(
                                                    itemcase.caseId,
                                                    [index, indexcase],
                                                    indexcase
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      itemcase.procedureSubjectShort
                                                    )
                                                  ),
                                                ]),
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(itemcase.caseName)
                                                  ),
                                                ]),
                                              ]),
                                              _c("div", [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(itemcase.briefAttr)
                                                  ),
                                                ]),
                                                _c("p", [_vm._v("部分胜诉")]),
                                                _c("i", {
                                                  staticClass:
                                                    "iconfont icon-dropdown-2",
                                                }),
                                              ]),
                                            ]
                                          ),
                                          item.caseAttrList[indexcase].showflag
                                            ? _c("span", [
                                                _vm.price
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "aggregate",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "left",
                                                          },
                                                          [
                                                            _c("p", [
                                                              _vm._v(
                                                                "收费汇总数据"
                                                              ),
                                                            ]),
                                                            _c("i", {
                                                              staticClass:
                                                                "iconfont icon-gengduo",
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "price",
                                                          },
                                                          [
                                                            _c("div", [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "give",
                                                                },
                                                                [_vm._v("已交")]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  "470000"
                                                                ),
                                                              ]),
                                                            ]),
                                                            _c("div", [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "give",
                                                                },
                                                                [_vm._v("应交")]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  "410000"
                                                                ),
                                                              ]),
                                                            ]),
                                                            _c("div", [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "give_three",
                                                                },
                                                                [_vm._v("欠费")]
                                                              ),
                                                              _c("p", [
                                                                _vm._v("60000"),
                                                              ]),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customer[index]
                                                  .caseAttrList[indexcase].data
                                                  .susong
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "request",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "request_left",
                                                          },
                                                          [
                                                            _c("p", [
                                                              _vm._v(
                                                                "诉讼请求"
                                                              ),
                                                            ]),
                                                            _c("i", {
                                                              staticClass:
                                                                "iconfont icon-gengduo",
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "ul",
                                                          {
                                                            staticClass:
                                                              "request_right",
                                                          },
                                                          [
                                                            _vm.customer[
                                                              index
                                                            ].caseAttrList[
                                                              indexcase
                                                            ].data.susong.indexOf(
                                                              "\n"
                                                            ) > -1
                                                              ? _c(
                                                                  "span",
                                                                  _vm._l(
                                                                    _vm.customer[
                                                                      index
                                                                    ].caseAttrList[
                                                                      indexcase
                                                                    ].data.susong.split(
                                                                      "\n"
                                                                    ),
                                                                    function (
                                                                      itemr,
                                                                      inder
                                                                    ) {
                                                                      return _c(
                                                                        "li",
                                                                        {
                                                                          key: inder,
                                                                          domProps:
                                                                            {
                                                                              innerHTML:
                                                                                _vm._s(
                                                                                  itemr
                                                                                ),
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              : _vm.customer[
                                                                  index
                                                                ].caseAttrList[
                                                                  indexcase
                                                                ].data.susong.indexOf(
                                                                  "^^^"
                                                                ) > -1
                                                              ? _c(
                                                                  "span",
                                                                  _vm._l(
                                                                    _vm.customer[
                                                                      index
                                                                    ].caseAttrList[
                                                                      indexcase
                                                                    ].data.susong.split(
                                                                      "^^^"
                                                                    ),
                                                                    function (
                                                                      itemrr,
                                                                      inderr
                                                                    ) {
                                                                      return _c(
                                                                        "li",
                                                                        {
                                                                          key: inderr,
                                                                          domProps:
                                                                            {
                                                                              innerHTML:
                                                                                _vm._s(
                                                                                  itemrr
                                                                                ),
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              : _c("li", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .customer[
                                                                          index
                                                                        ]
                                                                          .caseAttrList[
                                                                          indexcase
                                                                        ].data
                                                                          .susong
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customer[index]
                                                  .caseAttrList[indexcase].data
                                                  .caozuo
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "request",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "request_left",
                                                          },
                                                          [
                                                            _c("p", [
                                                              _vm._v(
                                                                "文书台操作记录"
                                                              ),
                                                            ]),
                                                            _c(
                                                              "div",
                                                              {
                                                                style: {
                                                                  display:
                                                                    "inline-block",
                                                                  transition:
                                                                    "all .5s",
                                                                  transform: _vm
                                                                    .customer[
                                                                    index
                                                                  ]
                                                                    .caseAttrList[
                                                                    indexcase
                                                                  ].data.caozuo[
                                                                    _vm
                                                                      .customer[
                                                                      index
                                                                    ]
                                                                      .caseAttrList[
                                                                      indexcase
                                                                    ].data
                                                                      .caozuo
                                                                      .length -
                                                                      1
                                                                  ].flag
                                                                    ? "rotate(90deg)"
                                                                    : "rotate(0deg)",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "iconfont icon-gengduo",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.document(
                                                                          index,
                                                                          indexcase
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "ul",
                                                          {
                                                            staticClass:
                                                              "request_right",
                                                          },
                                                          _vm._l(
                                                            _vm.customer[index]
                                                              .caseAttrList[
                                                              indexcase
                                                            ].data.caozuo[
                                                              _vm.customer[
                                                                index
                                                              ].caseAttrList[
                                                                indexcase
                                                              ].data.caozuo
                                                                .length - 1
                                                            ].flag
                                                              ? _vm.customer[
                                                                  index
                                                                ].caseAttrList[
                                                                  indexcase
                                                                ].data.caozuo
                                                              : _vm.customer[
                                                                  index
                                                                ].caseAttrList[
                                                                  indexcase
                                                                ].data.caozuo.slice(
                                                                  0,
                                                                  3
                                                                ),
                                                            function (
                                                              item,
                                                              num
                                                            ) {
                                                              return _c(
                                                                "li",
                                                                {
                                                                  key: num,
                                                                  attrs: {
                                                                    li: index,
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.time
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.name
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.customer[index]
                                                  .caseAttrList[indexcase].data
                                                  .wenshu
                                                  ? _c(
                                                      "li",
                                                      { staticClass: "book" },
                                                      _vm._l(
                                                        _vm.customer[
                                                          index
                                                        ].caseAttrList[
                                                          indexcase
                                                        ].data.wenshu.slice(
                                                          0,
                                                          3
                                                        ),
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                div: index,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.documentName
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._e(),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      _vm.list
                        ? _c("ul", { staticClass: "send" }, [
                            _c("li", { on: { click: _vm.cancel } }, [
                              _vm._v("取消"),
                            ]),
                            _c("li", [_vm._v("发送")]),
                          ])
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.getssnum === 2
        ? _c(
            "fb-page",
            [
              _c(
                "fb-header",
                {
                  attrs: {
                    slot: "header",
                    title: _vm.numtitle,
                    customer: false,
                    fixed: "",
                  },
                  slot: "header",
                },
                [
                  _c("fb-button", {
                    attrs: {
                      slot: "left",
                      icon: "back",
                      size: "small",
                      type: "primary",
                    },
                    on: {
                      click: function ($event) {
                        _vm.getssnum = 1
                      },
                    },
                    slot: "left",
                  }),
                  _c("template", { slot: "customer" }, [
                    _c(
                      "div",
                      {
                        staticClass: "hearder",
                        staticStyle: { "text-align": "left" },
                      },
                      [_vm._v("工商信息")]
                    ),
                  ]),
                ],
                2
              ),
              _c("ul", { staticClass: "information" }, [
                _c("li", [
                  _c("span", [_vm._v(_vm._s(this.information.companyName))]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("城市")]),
                  _c("span", [_vm._v(_vm._s(this.information.areaName))]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("统一社会信用代码")]),
                  _c("span", [_vm._v(_vm._s(this.companyCode))]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("注册号")]),
                  _c("span", [_vm._v(_vm._s(this.information.regNumber))]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("营业状态")]),
                  _c("span", [_vm._v(_vm._s(this.information.businessStatus))]),
                ]),
                _c("li", [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        this.information.regType === "其他"
                          ? "负责人"
                          : "法定代表人"
                      )
                    ),
                  ]),
                  _c("span", [_vm._v(_vm._s(this.faRen))]),
                ]),
                _c(
                  "li",
                  { staticClass: "address", on: { click: _vm.XianShi } },
                  [
                    _c("span", [_vm._v("企业地址")]),
                    !_vm.DiZhi
                      ? _c("span", [_vm._v(_vm._s(this.information.address))])
                      : _vm._e(),
                    _vm.DiZhi
                      ? _c("p", [_vm._v(_vm._s(this.information.address))])
                      : _vm._e(),
                  ]
                ),
                _c("li", [_c("span", [_vm._v("经营项目")])]),
                _c("li", [
                  _c("span", [_vm._v("注册资金")]),
                  _c("span", [
                    _vm._v(_vm._s(this.information.regM) + "万元人民币"),
                  ]),
                ]),
                _c("li", [_c("span", [_vm._v("营业期限")])]),
                _c(
                  "li",
                  { staticClass: "address", on: { click: _vm.JiGUan } },
                  [
                    _c("span", [_vm._v("登记机关")]),
                    !_vm.JiGuan
                      ? _c("span", [
                          _vm._v(_vm._s(this.information.regOrgName)),
                        ])
                      : _vm._e(),
                    _vm.JiGuan
                      ? _c("p", [_vm._v(_vm._s(this.information.regOrgName))])
                      : _vm._e(),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }