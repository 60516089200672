<template>
  <div class="views" v-if="show">
    <video
      ref="video"
      x5-video-player-type="h5"
      webkit-playsinline="true"
      x-webkit-airplay="true"
      x5-video-orientation="portraint"
      playsInline="true"
      x5-video-player-fullscreen="portraint"
      x5-video-ignore-metadata="true"
      style="width: 100%; height: calc(100vh - 22vh)"
      crossorigin="anonymous"
      autoplay
    ></video>
    <!-- <img style="width: 100vw; height: calc(100vh - 18vh)" :src="str" alt="" srcset=""> -->
    <div class="picture" @click="pictureClick">
      <i class="iconfont icon-picture"></i>
    </div>
    <div @click="handlePhotographClick" class="action"></div>
    <div class="folder" @click="folderClick">
      <i class="iconfont icon-folder"></i>
    </div>
    <div class="bac">
      <div style="position: absolute; bottom: -25px">
        <div ref="scrollContainer" style="overflow-x: scroll; white-space: nowrap; height: 22vh; padding: 0 20px; margin-right: 2rem">
          <div class="img_box" v-for="(img, index) in srcList" :key="img.src + index">
            <i class="iconfont icon-guanbi" @click="delImg(img.name, index)"></i>
            <img src="../../../../public/img/files1.png" v-if="img.name === 'files'" />
            <img :src="img.src" v-else />
            <span>{{ index + 1 }}</span>
          </div>
        </div>
      </div>
      <div style="overflow: hidden">
        <audio preload="auto"></audio>
        <div class="btn" @click="upload">确认</div>
      </div>
    </div>
    <van-popup v-model="lock" :close-on-click-overlay="false">
      <div class="text">
        上传中<br />请勿退出<br />以免上传失败！
        <div class="loading">
          <div class="loading-img">
            <img src="../../../../src/assets/loadings.jpg" />
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="err" :close-on-click-overlay="false"><div class="text red">网络错误</div></van-popup>
  </div>
  <div style="color: skyblue; font-size: 80px; text-align: center; margin-top: 40vh" v-else>上传成功</div>
</template>

<script>
// eslint-disable-next-line
import axios from 'axios'
// eslint-disable-next-line
import { obsBase, caseBase } from '@api-config'
import { uploadFile } from '../../../common/uploadfile'
import { readAndCompressImage } from 'browser-image-resizer'
export default {
  data() {
    return {
      fileName: '', // 上传文件名
      fileList: [], // 上传文件列表
      photoList: [], // 图片列表
      srcList: [], // 图片路径列表
      lock: false,
      show: true,
      userId: 0,
      token: '',
      caseId: 0,
      source: '',
      err: false,
      audioElement: null,
    }
  },
  mounted() {
    this.invokingCamera()
    this.audioElement = document.querySelector('audio')
    this.audioElement.src = 'https://oss.farbun.com/user/106350/843898833091039232/action.WAV'
    // console.log(this.$route.query.id)
    this.userId = this.$route.query.id
    this.token = this.$route.query.token
    this.caseId = this.$route.query.caseId ? this.$route.query.caseId : ''
    this.source = this.$route.query.source ? this.$route.query.source : ''
    console.log('caseID:', this.caseId, 'token:', this.token, 'userID:', this.userId, '展示不全>>>>>>>>>>>v2.8')
    // this.updateScanStatus(1)
  },
  methods: {
    scrollToEnd() {
      // 获取可滚动元素的引用
      const container = this.$refs.scrollContainer

      // 设置滚动位置为最大值，即滚动到最右侧
      if (container) {
        container.scrollLeft = container.scrollWidth + 800
        console.log('滚动到最右侧', container.scrollWidth + 800)
      }
    },
    // 调用摄像头
    invokingCamera() {
      const constraints = {
        video: {
          facingMode: { exact: 'environment' },
          // facingMode: 'user',
          width: Math.max(window.innerWidth, window.innerHeight) - 200,
          height: Math.min(window.innerWidth, window.innerHeight),
        },
      }
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // 现代浏览器支持
        navigator.mediaDevices.getUserMedia(constraints).then(this.handleSuccess).catch(this.handleError)
      } else if (navigator.getUserMedia) {
        // 对旧版浏览器的支持
        navigator.getUserMedia(constraints, this.handleSuccess, this.handleError)
      } else {
        console.error('----浏览器不支持摄像头!!!!!-----')
      }
    },
    handleSuccess(success) {
      this.isCamera = false
      // 摄像头开启成功
      this.$refs.video.srcObject = success
      // 播放摄像头画面
      const p = this.$refs.video.play()
      if (p !== undefined) {
        p.then(() => {
          this.$refs.video.pause()
          setTimeout(() => {
            this.$refs.video.play()
          }, 0)
        }).catch((e) => {
          console.log(e, 'undefinedPlay报错')
        })
      }
    },
    handleError(error) {
      console.error('摄像头开启失败，请检查摄像头是否可用！', error)
    },
    // 拍照
    async handlePhotographClick() {
      console.log('拍照开始')
      this.audioElement.play()
      console.log(this.audioElement, '音频播放完毕', new Date().getTime())
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const video = document.querySelector('video')
      const { videoWidth, videoHeight } = video
      let cw = 0
      let ch = 0
      if (videoWidth > 1000 || videoHeight > 1000) {
        cw = videoWidth
        ch = videoHeight
      } else {
        cw = videoWidth * devicePixelRatio
        ch = videoHeight * devicePixelRatio
      }
      canvas.width = Math.min(cw, ch)
      canvas.height = Math.max(cw, ch)
      console.log('-----vw-vh-------', videoWidth, videoHeight, cw, ch, devicePixelRatio)
      if (ctx.imageSmoothingQuality !== undefined) {
        ctx.imageSmoothingQuality = 'high'
      } else {
        ctx.imageSmoothingEnabled = true
      }
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      console.log('截取画面完成')
      // 将图片转为base64
      const base64Image = canvas.toDataURL('image/png')
      const type = base64Image.split(';')[0].split(':')[1]
      console.log('---压缩前base64Image--', base64Image.length / 1024 / 1024 + 'Mb', '图片类型：', type)
      // 图片压缩
      const config = {
        quality: 0.8,
        maxWidth: 1080,
        maxHeight: 1920,
        debug: true,
      }
      const blob = await this.dataURItoBlob(base64Image)
      const resizedImage = await readAndCompressImage(blob, config)
      const base64 = await this.convertBlobToBase64(resizedImage)
      console.log('-----resizedImage111------', resizedImage)
      console.log('-------压缩后base64Image------', base64.length / 1024 + 'kb', base64.length / 1024 / 1024 + 'Mb')
      if (base64 && base64.length > 0) {
        // 图片路径数组
        this.srcList.push({
          src: base64,
          // src: this.str,
          name: new Date().getTime() + '.png',
        })
        setTimeout(() => {
          this.scrollToEnd()
        }, 0)
      }
    },
    async dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1])
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ab], { type: mimeString })
    },
    async convertBlobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
    },
    folderClick() {
      // 选择文件
      var input = document.createElement('input')
      input.type = 'file'
      input.accept = '.doc,.docx,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf'
      input.addEventListener('change', (e) => {
        // 读取选择的文件
        this.fileList.push(e.target.files)
        this.srcList.push({
          src: null,
          name: 'files',
        })
        setTimeout(() => {
          this.scrollToEnd()
        }, 0)
      })
      // 触发点击事件，打开文件
      input.click()
    },
    pictureClick() {
      const self = this
      // 创建输入元素
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'image/*'
      input.multiple = true
      console.log('点击了图片')
      // 添加 change 事件处理器
      input.addEventListener('change', async function () {
        console.log(this.files, '-----')
        if (this.files && this.files.length > 0) {
          // 创建一个 Promise 队列来保证读取顺序
          let promiseQueue = Promise.resolve()
          for (let i = 0; i < this.files.length; i++) {
            // 将每个图片读取操作放入队列中，按顺序执行
            promiseQueue = promiseQueue.then(async () => {
              const file = this.files[i]
              return new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = (e) => {
                  const type = file.type.split('/')[1]
                  const imageData = {
                    src: e.target.result,
                    name: new Date().getTime() + '.' + type,
                  }
                  self.compressImg(e.target.result, 1, (data) => {
                    imageData.src = data
                    // 保持同步添加到 srcList 中
                    self.$set(self.srcList, self.srcList.length, imageData)
                    setTimeout(() => {
                      self.scrollToEnd()
                    }, 0)
                    resolve()
                  })
                }
                reader.readAsDataURL(file)
              })
            })
          }
          // 等待所有图片读取完成
          await promiseQueue
        }
      })

      // 触发点击事件
      input.click()
    },
    dataURLtoFile(dataURI, type) {
      const binary = atob(dataURI.split(',')[1])
      const array = []
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      return new File([new Uint8Array(array)], Date.now() + '.' + type, {
        type: `image/${type}`,
      })
    },
    delImg(name, index) {
      if (name === 'files') {
        this.fileList.splice(index, 1)
      }
      this.srcList.splice(index, 1)
    },
    textSearchUpload() {
      setTimeout(() => {
        if (this.srcList.length > 0) {
          const newSrcList = []
          const newFileList = []
          this.srcList.forEach((item) => {
            if (item.src !== null) {
              newSrcList.push(item.src)
            }
          })
          if (this.fileList && this.fileList.length > 0) {
            this.fileList.forEach((item) => {
              console.log('---item---', item[0])
              newFileList.push(item[0])
            })
          }
          console.log('---文本检索上传fileList---', newSrcList, newFileList)
          // 判断是否有图片或文件
          if (newSrcList.length > 0 && newFileList.length === 0) {
            this.handleImages(newSrcList).finally(() => {
              this.textSearchUploadState(0)
              console.log('---仅图片上传完成---', newSrcList)
            })
          } else if (newSrcList.length === 0 && newFileList.length > 0) {
            this.handleFiles(newFileList).finally(() => {
              this.textSearchUploadState(1)
              console.log('---仅文件上传完成---', newFileList)
            })
          } else if (newSrcList.length > 0 && newFileList.length > 0) {
            Promise.all([this.handleImages(newSrcList), this.handleFiles(newFileList)]).finally(() => {
              this.textSearchUploadState(2)
              console.log('---图片文件全部上传完成---', newSrcList, newFileList)
            })
          } else {
            console.log('---没有图片或文件---')
          }
        }
      }, 500)
    },
    // 封装图片处理逻辑
    async handleImages(imgArrayBase64) {
      if (imgArrayBase64.length > 0) {
        const imgDatas = {
          userId: this.userId,
          img: imgArrayBase64,
          type: '1',
        }
        axios.defaults.headers.common['Authentication-Token'] = this.token
        await axios
          .post(caseBase + '/management/accurateBatch', imgDatas)
          .then((res) => {
            if (res.status === 200 && res.data.code === 200 && res.data.data && res.data.data.length > 0) {
              console.log('---图片处理成功---', res.data.data)
            }
          })
          .catch((error) => {
            console.error('Image request failed:', error)
          })
      }
    },
    // 封装文件处理逻辑
    async handleFiles(files) {
      const fileUrlList = []
      const filePromises = files.map(async (file) => {
        console.log('---文件处理开始---', file)
        const key = `user/${this.userId}/${new Date().getTime()}/${file.name.replace(/#|%|\s/g, '')}`
        try {
          await this.uploadHW(file, key).then((res) => {
            console.log('---文件上传成功---', res)
            fileUrlList.push(obsBase + key)
          })
        } catch (error) {
          console.error('File upload failed:', error)
        }
      })

      await Promise.all(filePromises)

      const fileDatas = {
        userId: this.userId,
        docUrls: fileUrlList,
        type: '1',
      }
      console.log('---文件上传完成---', fileUrlList)

      axios.defaults.headers.common['Authentication-Token'] = this.token
      await axios
        .post(caseBase + '/management/getText', fileDatas)
        .then((res) => {
          if (res.status === 200 && res.data.code === 200 && res.data.data && res.data.data.length > 0) {
            console.log('---文件处理成功---', res.data.data)
          }
        })
        .catch((error) => {
          console.error('Image request failed:', error)
        })
    },
    textSearchUploadState(state) {
      console.log('---更新轮询结果---', state)
      const datas = {
        userId: this.userId,
        state: state,
      }
      // 调用pc端轮询接口
      axios.defaults.headers.common['Authentication-Token'] = this.token
      axios
        .get(caseBase + `/management/userTextSearch/selectPollStatus?userId=${datas.userId}&state=${datas.state}`)
        .then((res) => {
          setTimeout(() => {
            this.lock = false
            this.photoList = []
            this.srcList = []
            this.fileList = []
            this.$notify({ type: 'primary', message: '上传成功' })
            this.show = false
          }, 1000)
          console.log('---文件处理成功---', res)
        })
        .catch((error) => {
          console.error('Image request failed:', error)
        })
    },
    upload() {
      if (this.lock) return
      this.lock = true
      console.log('---来源---', this.source)
      if (this.source && this.source === 'textSearch') {
        this.textSearchUpload()
        return
      }
      setTimeout(() => {
        if (this.srcList.length > 0) {
          for (let i = 0; i < this.srcList.length; i++) {
            // 文件对象数组
            if (this.srcList[i].src === null) {
              continue
            }
            const type = this.srcList[i].name.split('.')[1]
            this.photoList.push(this.dataURLtoFile(this.srcList[i].src, type))
          }
          // 原来是let
          let fileObj = []
          for (let i = 0; i < this.fileList.length; i++) {
            fileObj.push(this.fileList[i][0])
          }
          for (let i = 0; i < this.photoList.length; i++) {
            fileObj.push(this.photoList[i])
          }
          const dataList = []
          // 上传文件
          for (let i = 0; i < fileObj.length; i++) {
            const file = fileObj[i]
            const key = `user/${this.userId}/${new Date().getTime()}/${file.name.replace(/#|%|\s/g, '')}`
            this.uploadHW(file, key).catch((e) => {
              this.srcList = []
              this.fileList = []
              console.log(e, '华为云上传报错原因')
              this.updateScanStatus(0)
              return false
            })
            dataList.push({
              fileName: fileObj[i].name,
              fileSize: fileObj[i].size,
              fileType: fileObj[i].type,
              filePath: obsBase + key,
              sort: i,
            })
          }
          if (dataList.length === fileObj.length) {
            const datas = {
              userId: this.userId,
              caseId: this.caseId,
              children: dataList,
            }
            console.log(datas, 'datassss-')
            axios.defaults.headers.common['Authentication-Token'] = this.token
            axios
              .post(caseBase + '/management/lbUserFile/saveFile', datas)
              .then((res) => {
                if (res.data.code === 200) {
                  setTimeout(() => {
                    this.lock = false
                    this.photoList = []
                    this.srcList = []
                    this.fileList = []
                    fileObj = []
                    this.$notify({ type: 'primary', message: '上传成功' })
                    this.updateScanStatus(2)
                    this.show = false
                  }, 1000)
                } else {
                  this.updateScanStatus(0)
                  this.err = true
                }
              })
              .catch((err) => {
                console.error(err, 'err')
                this.err = true
                this.updateScanStatus(0)
                setTimeout(() => {
                  this.show = true
                }, 1000)
              })
          }
        }
      }, 500)
    },
    // 华为云上传功能封装成Promise
    async uploadHW(file, key) {
      // 无论什么格式的统一上传到obs
      // const key = `user/${this.userId}/${new Date().getTime()}/${file.name.replace(/#|%|\s/g, '')}`
      // const key = `user/105817/${new Date().getTime()}/${file.name.replace(/#|%|\s/g, '')}`

      // 返回uploadFile方法的Promise结果
      return new Promise((resolve, reject) => {
        uploadFile(key, file)
          .then((res) => {
            console.log(res, 'res---成功key:', key)
            console.log('res---成功上传的文件：', file)
            resolve(res) // 成功时调用resolve，传递结果给外部
          })
          .catch((err) => {
            console.log(err, 'err-----失败原因-')
            reject(err) // 失败时调用reject，传递错误给外部
          })
      })
    },
    // 更新扫码状态
    updateScanStatus(state) {
      axios.defaults.headers.common['Authentication-Token'] = this.token
      axios
        .post(caseBase + '/management/lbUserFile/scanAndUpload', {
          userId: this.userId,
          uploadStatus: state,
          caseId: this.caseId,
        })
        .then((res) => {
          console.log(res.data, '更新状态成功')
        })
        .catch((err) => {
          console.error(err, '更新扫码状态失败')
          this.err = true
        })
    },
    compressImg(base64, multiple, useImg) {
      // 第一个参数就是需要加密的base64，
      // 第二个是压缩系数 0-1，
      // 第三个压缩后的回调 用来获取处理后的 base64
      const type = base64.split(';')[0].split(':')[1]
      console.log('base64压缩开始执行', type, base64.length / 1024)
      // console.log('base64压缩开始执行,参数为', base64)
      if (!base64) {
        return
      }
      // const _this = this
      const length = base64.length / 1024
      // 压缩方法
      const newImage = new Image()
      let quality = 0.6 // 压缩系数0-1之间
      newImage.src = base64
      newImage.setAttribute('crossOrigin', 'Anonymous') // url为外域时需要
      let imgWidth, imgHeight
      let w
      newImage.onload = function () {
        // 这里面的 this 指向 newImage
        // 通过改变图片宽高来实现压缩
        w = this.width * multiple
        imgWidth = this.width
        imgHeight = this.height
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        if (Math.max(imgWidth, imgHeight) > w) {
          if (imgWidth > imgHeight) {
            canvas.width = w
            // 等比例缩小
            canvas.height = w * (imgHeight / imgWidth)
          } else {
            canvas.height = w
            // 等比例缩小
            canvas.width = w * (imgWidth / imgHeight)
          }
        } else {
          canvas.width = imgWidth
          canvas.height = imgHeight
          // quality 设置转换为base64编码后图片的质量，取值范围为0-1  没什么压缩效果
          // 还是得通过设置 canvas 的宽高来压缩
          quality = 0.6
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(this, 0, 0, canvas.width, canvas.height) //  // 这里面的 this 指向 newImage
        const smallBase64 = canvas.toDataURL(type, quality) // 压缩语句
        // 如想确保图片压缩到自己想要的尺寸,如要求在50-150kb之间，请加以下语句，quality初始值根据情况自定
        // while (smallBase64.length / 1024 > 150) {
        // quality -= 0.01;
        // smallBase64 = canvas.toDataURL("image/jpeg", quality);
        // }
        // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
        // while (smallBase64.length / 1024 < 50) {
        // quality += 0.001;
        // smallBase64 = canvas.toDataURL("image/jpeg", quality);
        // }

        // 必须通过回调函数返回，否则无法及时拿到该值，回调函数异步执行
        console.log(`压缩前：${length}KB`)
        console.log(`压缩后：${smallBase64.length / 1024} KB`)
        // console.log(smallBase64)
        // return smallBase64
        useImg(smallBase64)
      }
    },
  },
}
</script>

<style scoped lang="stylus">
.loading-box
  padding 20px 0px
  position: absolute;
  top: 40vh;
  width: 100vw;
  left: 50%;
  transform: translate(-10vw);
.views{
  // width: 100vw;/
  height: 100vh;
  background-color: #ccc;
  position: relative
  overflow hidden;
  video{
    // transform: rotate(90deg) scale(1.15);
    transform:scale(1.15);
  }

  .picture{
    position: absolute;
    left: 1rem;
    z-index: 9;
    bottom: 5rem;
    color: #fff;
    .icon-picture{
    font-size: 1rem;
    }
  }
  .folder{
    position: absolute;
    right: 1rem;
    z-index: 9;
    bottom: 5rem;
    color: #fff;
   .icon-folder{
      font-size: 1rem;
    }
  }
  .action{
  position: absolute;
  bottom: 23vh;
  left: 50%;
  margin-left: -44px;
  border-radius: 50px;
  border: 10px solid #ccc;
  box-shadow: 0 0 10px #b1b1b1;
  background-color: #fff;
  width: 88px;
  height: 88px;
  display: flex;
  justify-content: center;
  z-index :99
  }
.bac {
    overflow-x: scroll;
    white-space: nowrap; /* 横向内容不换行 */
    background-color: #000;
    height:22vh;
  }

  .img_box {
    position: relative;
    top: 0.5rem;
    display: inline-block; /* img_box横向排列 */
    margin-left: 8px;
    z-index :99
    img {
    // width: 100px;
    height:14vh;
    margin-left: 8px
    z-index :99
  }
  }

  .icon-guanbi {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    font-size : 20px;
    color #FF5F60;
    border:10px solid #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  span {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 4px;
    margin-bottom: 4px;
    color: #fff;
    background-color: #64e8ff;
    padding: 4px;
    font-size: 20px;
    border-radius: 2px 2px 2px 6px;
  }
  .btn{
    height: 15vh;
    background-color: #000;
    width: 2rem;
    line-height: 15vh;
    text-align: center;
    color: #fff;
    position: absolute;
    right: 0;
    z-index: 109;
    bottom: 0;
    font-size: 1rem
  }
}
.text{
  color: #000;
  font-size: 18px;
  padding:15px;
}
.red{
  color: red;
}
/* loading 动画容器 */
.loading {
  width: 48px; /* 调整loading图标大小 */
  height: 48px;
  position: absolute;
  top: 15px;
  right: 15px;
}

/* loading 图像样式 */
.loading-img img {
  border-radius: 50%;
  width: 100%;
  height: auto;
  animation: spin 1s linear infinite; /* 添加旋转动画 */
}

/* 定义旋转动画 */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
