import ObsClient from 'esdk-obs-browserjs/src/obs'

// 创建ObsClient实例
let uploadFn = null
var obsClient = new ObsClient({
  access_key_id: 'OD81QB6XFEO3K6L5PQCT',
  secret_access_key: 'gc6xJwnErcnUIdelwKAhdMYeU6bMjchHawAhaOrc',
  server: 'https://obs.cn-north-4.myhuaweicloud.com',
})
uploadFn = (key, localFile) => {
  return new Promise((resolve, reject) => {
    obsClient.putObject(
      {
        Bucket: 'webobs',
        Key: key,
        SourceFile: localFile,
        ContentDisposition: 'inline',
      },
      (err, result) => {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      }
    )
  })
}

export const uploadFile = uploadFn
