var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ul", { staticClass: "check_box" }, [
    _c("li", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.checked,
          expression: "checked",
        },
      ],
    }),
    _c("li", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.checked,
          expression: "checked",
        },
      ],
    }),
    _c("input", {
      attrs: { type: "checkBox" },
      domProps: { checked: _vm.checked },
      on: {
        change: function ($event) {
          return _vm.$emit("change", $event.target.checked)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }