export const tools = {
  scrollLoading (element, callback) {
    let num = 1
    let res = true
    element.addEventListener(
      'scroll',
      function () {
        if (
          this.scrollTop === element.scrollHeight - element.offsetHeight &&
          res
        ) {
          num++
          res = callback(num)
          if (res !== false) {
            res = true
          }
        }
      },
      false
    )
  }
}
