<template>
  <div style="width: 100%; height: 100%">
    <!-- 获取客户列表 -->
    <fb-page ref="ctn" style="background: #ffffff" v-if="getssnum === 1">
      <fb-header
        slot="header"
        :title="'我的客户(' + this.title + ')'"
        :customer="true"
        fixed
      >
        <fb-button
          slot="left"
          icon="back"
          @click="handleGoBack"
          size="small"
          type="primary"
        >
        </fb-button>
        <template slot="customer">
          <!-- <div class="header_icon">客户关怀</div>
        <i class="iconfont icon-02 header_icon"></i> -->
          <i class="iconfont icon-add header_icon" @click="added"></i>
        </template>
      </fb-header>
      <ul
        class="screen"
        :style="{
          'border-bottom': caseli || custype || cusroad ? '1px solid #ccc' : '',
        }"
      >
        <li @click="qiehuan(1)" :style="{ color: caseli ? '#4B9EFB' : '#333' }">
          <span>案件</span>
          <i
            class="iconfont icon-daka-copy"
            :style="{ color: caseli ? '#4B9EFB' : '#ccc' }"
          ></i>
        </li>
        <li @click="qiehuan(2)" :style="{ color: custype ? '#4B9EFB' : '#333' }">
          <span>客户类型</span>
          <i
            class="iconfont icon-daka-copy"
            :style="{ color: custype ? '#4B9EFB' : '#ccc' }"
          ></i>
        </li>
        <li @click="qiehuan(3)" :style="{ color: cusroad ? '#4B9EFB' : '#333' }">
          <span>客户来源</span>
          <i
            class="iconfont icon-daka-copy"
            :style="{ color: cusroad ? '#4B9EFB' : '#ccc' }"
          ></i>
        </li>
      </ul>
      <div class="polit" @scroll="getload($event)" v-if="caseli || custype || cusroad">
        <ul class="custype" v-if="caseli">
          <li
            v-for="(item, index) in getlist"
            :key="index"
            :class="[item.caseId === cad ? 'addclass' : '']"
            @click="caseid(index, item.caseId, item.title)"
          >
            {{ item.title }}
          </li>
        </ul>
        <ul class="custype" v-if="custype">
          <li
            :class="[active === 0 ? 'addclass' : '']"
            @click="
              active = 0
              getscreen()
              caseli = false
              custype = false
              cusroad = false
            "
          >
            自然人
          </li>
          <li
            :class="[active === 1 ? 'addclass' : '']"
            @click="
              active = 1
              getscreen()
              caseli = false
              custype = false
              cusroad = false
            "
          >
            法人
          </li>
          <li
            :class="[active === 2 ? 'addclass' : '']"
            @click="
              active = 3
              getscreen()
              caseli = false
              custype = false
              cusroad = false
            "
          >
            非法人
          </li>
        </ul>
        <ul class="custype" v-if="cusroad">
          <li
            :class="[cuactive === 0 ? 'addclass' : '']"
            @click="
              cuactive = 0
              getscreen()
              caseli = false
              custype = false
              cusroad = false
            "
          >
            未知
          </li>
          <li
            :class="[cuactive === 1 ? 'addclass' : '']"
            @click="
              cuactive = 1
              getscreen()
              caseli = false
              custype = false
              cusroad = false
            "
          >
            上门服务
          </li>
          <li
            :class="[cuactive === 2 ? 'addclass' : '']"
            @click="
              cuactive = 2
              getscreen()
              caseli = false
              custype = false
              cusroad = false
            "
          >
            介绍
          </li>
          <li
            :class="[cuactive === 3 ? 'addclass' : '']"
            @click="
              cuactive = 3
              getscreen()
              caseli = false
              custype = false
              cusroad = false
            "
          >
            网络
          </li>
          <li
            :class="[cuactive === 4 ? 'addclass' : '']"
            @click="
              cuactive = 4
              getscreen()
              caseli = false
              custype = false
              cusroad = false
            "
          >
            顾问单位
          </li>
          <li
            :class="[cuactive === 5 ? 'addclass' : '']"
            @click="
              cuactive = 5
              getscreen()
              caseli = false
              custype = false
              cusroad = false
            "
          >
            电话
          </li>
          <li
            :class="[cuactive === 6 ? 'addclass' : '']"
            @click="
              cuactive = 6
              getscreen()
              caseli = false
              custype = false
              cusroad = false
            "
          >
            其他
          </li>
        </ul>
      </div>
      <div class="customer" v-if="!(caseli || cusroad || custype)">
        <div
          class="customer_name"
          v-for="(item, index) in customer"
          :key="index"
          div="index"
        >
          <!-- 自然人 -->
          <ul class="name" v-if="item.categoryEnum == 'NATURAL_PERSON'">
            <li>
              <span class="person" @click="modify(item.customerId, $event)">{{
                item.customerName
              }}</span>
              <span>{{ item.sex }}</span>
              <span v-if="item.age">{{ item.age }}岁</span>
            </li>
            <li class="phone">
              <i
                class="iconfont icon-dianhua1"
                v-if="item.phone"
                @click="PhoneDian(item.phone)"
              ></i>
              <span>{{ item.phone }}</span>
            </li>
            <i
              class="iconfont icon-gengduo"
              @click="customner(index)"
              :style="{
                transform: customer[index].showflag ? 'rotate(90deg)' : 'rotate(0deg)',
              }"
            ></i>
          </ul>
          <!-- 法人/非法人 -->
          <ul class="company" v-if="item.categoryEnum != 'NATURAL_PERSON'">
            <li>
              <i class="iconfont icon-qiye" @click="sinformation(item.customerName)"></i>
              <span @click="modify(item.customerId)">{{ item.customerName }}</span>
              <i
                class="iconfont icon-gengduo"
                @click="customner(index)"
                :style="{
                  transform: customer[index].showflag ? 'rotate(90deg)' : 'rotate(0deg)',
                }"
              ></i>
            </li>
            <li v-if="!(!item.manager && !item.position)">
              <span
                >{{ item.categoryEnum == 'LEGAL_ENTITY' ? '法定' : '负责' }}代表人：</span
              >
              <span v-if="item.manager">{{ item.manager }}</span>
              <span v-if="item.position">{{ item.position }}</span>
            </li>
          </ul>
          <!-- 内容模块 -->
          <ul class="case" v-if="!customer[index].showflag">
            <li
              v-for="(itemlist, indexlist) in item.caseAttrList
                ? item.caseAttrList.slice(0, 3)
                : ''"
              :key="indexlist"
              :li="indexlist"
            >
              <span>{{ itemlist.procedureSubjectShort }}</span>
              <p>{{ itemlist.caseName }}</p>
            </li>
          </ul>

          <ul class="care" v-if="list">
            <li>
              <span style="color: #4b9efb">原</span>
              <span>张三诉南京华为信息科技有限公司劳动纠纷案</span>
              <div class="box">
                <check-box v-model="selected"></check-box>
              </div>
            </li>
            <li>
              <span>上诉期：</span>
              <span>2018.2.12-2018.2.27</span>
            </li>
            <li>（距离结束日期还剩1天）</li>
            <li class="written">
              <textarea
                type="text"
                placeholder="在此处添加其他文字内容，我们将把上面的内容，加上此处的文字，以短信的形式发送给该客户。"
              ></textarea>
            </li>
          </ul>
          <!-- 我的客户 -->
          <!-- 点击展开 -->
          <span
            v-if="customer[index].showflag"
            style="background-color: #ffffff; display: inline-block; width: 100%"
          >
            <ul
              class="open"
              v-for="(itemcase, indexcase) in item.caseAttrList"
              :key="indexcase"
              :ul="indexcase"
            >
              <li
                class="open_case"
                @click="caselist(itemcase.caseId, [index, indexcase], indexcase)"
              >
                <div>
                  <p>{{ itemcase.procedureSubjectShort }}</p>
                  <p>{{ itemcase.caseName }}</p>
                </div>
                <div>
                  <p>{{ itemcase.briefAttr }}</p>
                  <p>部分胜诉</p>
                  <i class="iconfont icon-dropdown-2"></i>
                </div>
              </li>
              <span v-if="item.caseAttrList[indexcase].showflag">
                <li class="aggregate" v-if="price">
                  <div class="left">
                    <p>收费汇总数据</p>
                    <i class="iconfont icon-gengduo"></i>
                  </div>
                  <div class="price">
                    <div>
                      <p class="give">已交</p>
                      <p>470000</p>
                    </div>
                    <div>
                      <p class="give">应交</p>
                      <p>410000</p>
                    </div>
                    <div>
                      <p class="give_three">欠费</p>
                      <p>60000</p>
                    </div>
                  </div>
                </li>
                <li
                  class="request"
                  v-if="customer[index].caseAttrList[indexcase].data.susong"
                >
                  <div class="request_left">
                    <p>诉讼请求</p>
                    <i class="iconfont icon-gengduo"></i>
                  </div>
                  <ul class="request_right">
                    <span
                      v-if="
                        customer[index].caseAttrList[indexcase].data.susong.indexOf(
                          '\n'
                        ) > -1
                      "
                    >
                      <li
                        v-for="(itemr, inder) in customer[index].caseAttrList[
                          indexcase
                        ].data.susong.split('\n')"
                        :key="inder"
                        v-html="itemr"
                      ></li>
                    </span>
                    <span
                      v-else-if="
                        customer[index].caseAttrList[indexcase].data.susong.indexOf(
                          '^^^'
                        ) > -1
                      "
                    >
                      <li
                        v-for="(itemrr, inderr) in customer[index].caseAttrList[
                          indexcase
                        ].data.susong.split('^^^')"
                        :key="inderr"
                        v-html="itemrr"
                      ></li>
                    </span>
                    <li v-else>
                      {{ customer[index].caseAttrList[indexcase].data.susong }}
                    </li>
                  </ul>
                </li>
                <li
                  class="request"
                  v-if="customer[index].caseAttrList[indexcase].data.caozuo"
                >
                  <div class="request_left">
                    <p>文书台操作记录</p>
                    <div
                      :style="{
                        display: 'inline-block',
                        transition: 'all .5s',
                        transform: customer[index].caseAttrList[indexcase].data.caozuo[
                          customer[index].caseAttrList[indexcase].data.caozuo.length - 1
                        ].flag
                          ? 'rotate(90deg)'
                          : 'rotate(0deg)',
                      }"
                    >
                      <i
                        class="iconfont icon-gengduo"
                        @click="document(index, indexcase)"
                      ></i>
                    </div>
                  </div>
                  <ul class="request_right">
                    <li
                      v-for="(item, num) in customer[index].caseAttrList[indexcase].data
                        .caozuo[
                        customer[index].caseAttrList[indexcase].data.caozuo.length - 1
                      ].flag
                        ? customer[index].caseAttrList[indexcase].data.caozuo
                        : customer[index].caseAttrList[indexcase].data.caozuo.slice(0, 3)"
                      :key="num"
                      :li="index"
                    >
                      <span>{{ item.time }}</span>
                      <span>{{ item.name }}</span>
                    </li>
                  </ul>
                </li>

                <li
                  class="book"
                  v-if="customer[index].caseAttrList[indexcase].data.wenshu"
                >
                  <div
                    v-for="(item, index) in customer[index].caseAttrList[
                      indexcase
                    ].data.wenshu.slice(0, 3)"
                    :key="index"
                    :div="index"
                  >
                    {{ item.documentName }}
                  </div>
                </li>
              </span>
            </ul>
          </span>
        </div>
        <ul class="send" v-if="list">
          <li @click="cancel">取消</li>
          <li>发送</li>
        </ul>
      </div>
    </fb-page>
    <!-- 工商信息 -->
    <fb-page v-if="getssnum === 2">
      <fb-header slot="header" :title="numtitle" :customer="false" fixed>
        <fb-button
          slot="left"
          icon="back"
          @click="getssnum = 1"
          size="small"
          type="primary"
        >
        </fb-button>
        <template slot="customer">
          <div class="hearder" style="text-align: left">工商信息</div>
        </template>
      </fb-header>
      <ul class="information">
        <li>
          <span>{{ this.information.companyName }}</span>
        </li>
        <li>
          <span>城市</span>
          <span>{{ this.information.areaName }}</span>
        </li>
        <li>
          <span>统一社会信用代码</span>
          <span>{{ this.companyCode }}</span>
        </li>
        <li>
          <span>注册号</span>
          <span>{{ this.information.regNumber }}</span>
        </li>
        <li>
          <span>营业状态</span>
          <span>{{ this.information.businessStatus }}</span>
        </li>
        <li>
          <span>{{ this.information.regType === '其他' ? '负责人' : '法定代表人' }}</span>
          <span>{{ this.faRen }}</span>
        </li>
        <li class="address" @click="XianShi">
          <span>企业地址</span>
          <span v-if="!DiZhi">{{ this.information.address }}</span>
          <p v-if="DiZhi">{{ this.information.address }}</p>
        </li>
        <li>
          <span>经营项目</span>
        </li>

        <li>
          <span>注册资金</span>
          <span>{{ this.information.regM }}万元人民币</span>
        </li>
        <li>
          <span>营业期限</span>
        </li>
        <li @click="JiGUan" class="address">
          <span>登记机关</span>
          <span v-if="!JiGuan">{{ this.information.regOrgName }}</span>
          <p v-if="JiGuan">{{ this.information.regOrgName }}</p>
        </li>
      </ul>
    </fb-page>
  </div>
</template>
<script>
import checkBox from './checkBox'
import { caseBase } from '~api-config'
import { tools } from '../tool'
import goBackMixin from '@mixins/goBack'
export default {
  name: 'Client',
  components: { checkBox },
  props: ['params', 'List'],
  mixins: [goBackMixin],
  data() {
    return {
      cuactive: '',
      caseact: '',
      active: '',
      caseli: false,
      custype: false,
      cusroad: false,
      tpelist: 0,
      title: '',
      disappear: false,
      list: false,
      selected: false,
      customer: [],
      natural: true,
      case_list: false,
      showLoading: true,
      price: false,
      pagenum: 1,
      getnum: 1,
      getlist: [],
      hatin: true,
      painan: true,
      cad: '',
      kk: false,
      getssnum: 1,
      numtitle: '',
      DiZhi: false,
      JiGuan: false,
      faRen: '',
      companyCode: '', // 统一社会信用码
      information: {
        companyName: '', // 公司名称
        areaName: '', // 城市
        regNumber: '', // 注册号
        businessStatus: '', // 营业状态
        // 法定代表人
        address: '', // 企业地址
        regM: '', // 注册资金
        regOrgName: '', // 登记机关,
        regType: '',
      },
    }
  },
  methods: {
    XianShi() {
      this.DiZhi = !this.DiZhi
    },
    JiGUan() {
      this.JiGuan = !this.JiGuan
    },
    getscreen() {
      setTimeout(() => {
        this.kk = true
        // 查找列表
        this.$axios
          .get(
            `${caseBase}/lts/customer/multipleQueryCustomer?pageNum=1&limit=199&caseId=${this.cad}&category=${this.active}&source=${this.cuactive}`
          )
          .then((res) => {
            // active 0为自然人 1为法人 2为非法人
            // cuactive 0未知；1上门服务；2介绍：3网络；4顾问单位;5电话;6其他
            if (res.data.code === '200') {
              if (res.data.data.customerBriefInfList.length > 0) {
                this.title = res.data.data.total
                res.data.data.customerBriefInfList.forEach(function (item, index, array) {
                  // 点击排他功能切换
                  array[index].showflag = false
                  if (array[index].caseAttrList) {
                    array[index].caseAttrList.forEach(function (item, index, array) {
                      array[index].data = {}
                      array[index].showflag = false
                    })
                  }
                })
                this.$toast('查询成功')
              }
              this.customer = res.data.data.customerBriefInfList
              setTimeout(() => {
                this.$refs.ctn.$el.scrollTop = this.$store.state.client.Options.is_added
                  ? 0
                  : this.$store.state.client.Options.scrollHeight
                this.$store.state.client.Options.scrollHeight = ''
              })
            } else {
              this.$toast(res.data.message)
            }
          })
      }, 300)
    },
    // 案件筛选
    caseid(index, caseid, title) {
      this.caseact = index
      this.cad = caseid
    },
    getload(e) {
      // if ((e.target.scrollTop + e.target.offsetHeight).toFixed() >= e.target.scrollHeight && this.hatin) {
      //   this.getnum++
      //   this.getcase()
      // }
    },
    // 获取案件列表
    getcase() {
      this.$axios.get(`${caseBase}/lts/case/attrs?0limit=20`).then((res) => {
        if (res.data.data.caseAttrList.length === 0) {
          this.hatin = false
          this.$toast('没有更多了')
        } else {
          this.$axios
            .get(`${caseBase}/lts/case/attrs?0limit=${res.data.data.total}`)
            .then((res) => {
              this.getlist = res.data.data.caseAttrList
              this.$emit('getlist', res.data.data.caseAttrList)
            })
        }
      })
    },
    qiehuan(val) {
      if (val === 1) {
        this.caseli = !this.caseli
        this.custype = false
        this.cusroad = false
      } else if (val === 2) {
        this.custype = !this.custype
        this.caseli = false
        this.cusroad = false
      } else {
        this.cusroad = !this.cusroad
        this.caseli = false
        this.custype = false
      }
    },
    // 返回方法
    handleGoBack() {
      // 目前是判断有没有参数传过来
      const { customerId, litigantId } = this.$route.query
      // web页面参数如果存在
      if (customerId || litigantId) {
        this.$router.back()
      } else {
        this.backToNative()
      }
    },
    PhoneDian(phone) {
      this.$dialog
        .alert({
          title: '是否需要拨打电话？',
          message: `<h1 style="color:#4B9EFB;font-size:18px">${phone}</h1>`,
          confirmButtonText: '拨打电话',
          closeOnClickOverlay: true,
        })
        .then(() => {
          try {
            const u = navigator.userAgent
            if (u === 'fb-Android-Webview') {
              // 安卓环境
              // eslint-disable-next-line
              AND2JS.call(phone)
            } else if (u === 'fb-iOS-Webview') {
              // ios环境
              // eslint-disable-next-line
              window.webkit.messageHandlers.backToTool.postMessage({
                takePhoneNumber: phone,
              })
            } else if (u === 'fb-flutter-Webview') {
              // eslint-disable-next-line
              window.backToTool.postMessage(
                JSON.stringify({
                  takePhoneNumber: phone,
                })
              )
            } else {
              this.$toast('非原生环境,返回原生指定页')
            }
          } catch (err) {
            console.log('backToTool.postMessage', err)
          }
        })
    },
    customner(index) {
      // 点击排他功能切换
      this.customer.forEach((item, num, array) => {
        if (num === index) {
          return
        }
        array[num].showflag = false
      })
      this.customer[index].showflag = !this.customer[index].showflag
    },
    // 切换index
    document(index, indexcase) {
      const caozuoIndex =
        this.customer[index].caseAttrList[indexcase].data.caozuo.length - 1
      this.$set(
        this.customer[index].caseAttrList[indexcase].data.caozuo[caozuoIndex],
        'flag',
        !this.customer[index].caseAttrList[indexcase].data.caozuo[caozuoIndex].flag
      )
    },
    // vuex存储高度
    modify(id, e) {
      this.$store.state.client.Options = {
        pagenum: this.pagenum,
        scrollHeight: this.$refs.ctn.$el.scrollTop,
      }
      this.$store.state.client.Options.is_added = false
      this.$emit('moreinfo', id)
    },
    // 客户关怀
    // customercare () {
    //   this.num = true
    //   this.list = true
    // },
    cancel() {
      this.list = false
    },
    added() {
      this.$emit('moreinfo', '')
    },
    sinformation(name) {
      this.$axios
        .get(`${caseBase}/management/business?name=${name}`)
        .then((res) => {
          this.getssnum = 2
          res.data.forEach((item) => {
            for (const itemkey in res.data[0]) {
              this.information[itemkey] = res.data[0][itemkey]
            }
          })
          this.$axios
            .get(
              `${caseBase}/management/getInfoByKey?companyName=${res.data[0].companyName}`
            )
            .then((res) => {
              this.companyCode = res.data.creditCode
              this.faRen = res.data.faRen
            })
        })
        .catch((err) => {
          this.$notify('获取结果失败')
          Promise.reject(err)
        })
    },
    caselist(id, arrindex) {
      this.customer[arrindex[0]].caseAttrList[arrindex[1]].showflag =
        !this.customer[arrindex[0]].caseAttrList[arrindex[1]].showflag
      if (!this.customer[arrindex[0]].caseAttrList[arrindex[1]].showflag) {
        this.case_list = !this.case_list
        return false
      }
      const params = {
        caozuo: [],
        susong: '',
        wenshu: [],
      }
      this.$axios
        .get(`${caseBase}/document/case/queryCaseInfByCaseId?caseId=${id}`)
        .then((res) => {
          const data = res.data.data
          params.susong = data.litigationRequest ? data.litigationRequest : null
          if (data.operationRecordList) {
            data.operationRecordList.forEach((item, index, array) => {
              const createtime = new Date(array[index].creatTime)
              const create = `${createtime.getFullYear()}.${
                createtime.getMonth() + 1
              }.${createtime.getDate()}`
              params.caozuo.push({
                name: array[index].documentName,
                tieme: create,
              })
            })
          } else {
            params.caozuo = null
          }
          params.wenshu = data.editedDocumentsList ? data.editedDocumentsList : null
          if (!params.susong && !params.caozuo && !params.wenshu) {
            this.$toast('暂无数据')
          }
          this.case_list = !this.case_list
          const _data = this.customer[arrindex[0]].caseAttrList[arrindex[1]]
          _data.data = params
          this.$set(this.customer[arrindex[0]].caseAttrList, arrindex[1], _data)
          // 文书台操作记录
          this.customer[arrindex[0]].caseAttrList.forEach((item, index) => {
            if (this.customer[arrindex[0]].caseAttrList[index].data.caozuo) {
              this.customer[arrindex[0]].caseAttrList[index].data.caozuo.push({
                flag: false,
              })
            }
          })
        })
        .catch((err) => {
          this.$toast('获取数据失败')
          Promise.reject(err)
        })
    },
    casebase(customerId, litigantId, page = 1) {
      const url = litigantId
        ? `${caseBase}/lts/customer/queryUserCustomer?customerId=${litigantId}&pageNum=${page}`
        : `${caseBase}/lts/customer/queryUserCustomer?pageNum=${page}`
      this.$axios
        .get(
          customerId
            ? `${caseBase}/lts/customer/queryUserCustomer?customerId=${customerId}&pageNum=${page}`
            : url
        )
        .then((res) => {
          this.$toast.clear()
          this.title = res.data.data.total // 获取我的客户数量
          if (res.data.data.customerBriefInfList.length === 0) {
            this.painan = false
            this.$toast('没有更多了')
          }
          res.data.data.customerBriefInfList.forEach(function (item, index, array) {
            // 点击排他功能切换
            array[index].showflag = false
            if (array[index].caseAttrList) {
              array[index].caseAttrList.forEach(function (item, index, array) {
                array[index].data = {}
                array[index].showflag = false
              })
            }
          })
          this.customer = this.customer.concat(res.data.data.customerBriefInfList)
        })
    },
  },
  watch: {
    // 案件监听
    cad: {
      handler(val) {
        this.caseli = false
        this.custype = false
        this.cusroad = false
        this.$emit('setparams', 'cad', val)
        this.getscreen()
      },
    },
    // 客户类型
    active: {
      handler(val) {
        this.caseli = false
        this.custype = false
        this.cusroad = false
        this.$emit('setparams', 'active', val)
        this.getscreen()
      },
    },
    // 客户涞源
    cuactive: {
      handler(val) {
        this.caseli = false
        this.custype = false
        this.cusroad = false
        this.$emit('setparams', 'cuactive', val)
        this.getscreen()
      },
    },
  },
  mounted() {
    if (this.List) {
      this.getlist = this.List
    } else {
      // 获取案件列表
      this.getcase()
    }
    // 懒加载
    this.scroll_fn = (pagenum) => {
      if (this.kk) {
        console.log('此时为查询状态')
        return false
      }
      if (this.painan) {
        this.$toast.loading({
          mask: true,
          message: '加载中...',
        })
        this.pagenum++
        this.casebase(
          this.$route.query.customerId,
          this.$route.query.litigantId,
          this.pagenum
        )
      }
    }
    // 滚动条滚动事件
    tools.scrollLoading(this.$refs.ctn.$vnode.elm, this.scroll_fn)
    if (
      Object.values(this.params).filter((item) => {
        return item.toString()
      }).length > 0
    ) {
      for (const item in this.params) {
        if (this.params.pagenum) {
          continue
        }
        this[item] = this.params[item]
      }
      console.log(this.params.cad)
    } else {
      // 获取客户列表
      // console.log('获取客户列表')
      // this.casebase(this.$route.query.customerId, this.$route.query.litigantId)
    }
    if (this.$store.state.client.Options.scrollHeight) {
      if (
        Object.values(this.params).filter((item) => {
          return item.toString()
        }).length === 0
      ) {
        // 非查询状态
        const arr = []
        for (let i = 1; i <= this.$store.state.client.Options.pagenum; i++) {
          // push
          arr.push(
            this.$axios.get(`${caseBase}/lts/customer/queryUserCustomer?pageNum=${i}`)
          )
        }
        this.$axios.all(arr).then((res) => {
          res = res.sort((f, s) => {
            return (
              Number(f.config.url.slice(f.config.url.lastIndexOf('=') + 1)) <
              Number(s.config.url.slice(s.config.url.lastIndexOf('=') + 1))
            )
          })
          this.pagenum = res.length
          res.forEach((data) => {
            if (data.data.data.customerBriefInfList) {
              this.customer = this.customer.concat(data.data.data.customerBriefInfList)
              this.title = res[0].data.data.total
              setTimeout(() => {
                this.$refs.ctn.$el.scrollTop = this.$store.state.client.Options.is_added
                  ? 0
                  : this.$store.state.client.Options.scrollHeight
              })
            }
          })
        })
      }
      // 回显滚动条高度
    } else {
      this.casebase()
    }
  },
}
</script>
<style lang="stylus" scoped>
/deep/ .fb-header-customer
  background #ffffff !important
.fb-page
  background #F8F8F8
  position relative
  width 100%
  height 100%
  overflow auto
  padding-bottom 15px
  background #ffffff !important
  .hearder
    width 100%
    text-align left
  .information
    width 95%
    margin 0px auto
    li:nth-child(1)
      span
        width 100% !important
    li
      width 100%
      min-height 44px
      line-height 44px
      border-bottom 1px solid #f2f2f2
      overflow hidden
      color #666666
      font-size 15px
      display flex
      justify-content space-between
      span:nth-child(1)
        width 50%
      span:nth-child(2)
        width 50%
        white-space nowrap
        text-overflow ellipsis
        overflow hidden
        text-align right
    .address
      position relative
      z-index 99
      span:nth-child(2)
        width 70%
        text-align right
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
      p
        width 70%
        line-height 25px
        background-color white
  .header_icon
    margin-right 13px
    flex 1
  .addclass
    color rgb(75, 158, 251)
  .polit
    width 100%
    max-height 280px
    overflow auto
    margin-top 1px
    background rgba(255, 255, 255, 1)
    box-shadow 0px 4px 8px 1px rgba(190, 190, 190, 0.64)
    border-radius 5px
    margin-bottom 10px
  .custype
    width 90%
    margin 0px auto
    margin-bottom 20px
    overflow auto
    height 100%
    li
      width 100%
      height 36px
      line-height 36px
      border-bottom 1px solid #ccc
      font-size 14px
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
  .screen
    width 90%
    height 30px
    margin 0px auto
    line-height 30px
    display flex
    font-size 14px
    color #333333
    font-weight 400
    justify-content space-between
    margin-top 5px
    .icon-daka-copy
      color #ccc
      align-self center
      font-size 12px
  .customer
    .customer_name
      width 90%
      margin 0px auto
      background rgba(255, 255, 255, 1)
      box-shadow 0px 0px 38px 0px rgba(164, 164, 164, 0.2)
      border-radius 10px
      padding-bottom 20px
      overflow hidden
      margin-top 10px
      .name
        width 100%
        display flex
        justify-content space-between
        font-size 15px
        color #999999
        position relative
        li
          margin-top 8px
          align-self center
        .person
          color #000000
        li:nth-child(1)
          span
            margin-left 15px
        li:nth-child(2)
          margin-right 35px
          span
            color #000000
          .icon-dianhua1
            margin-right 8px
            color #5DCD43
        .icon-gengduo
          position absolute
          color #333333
          transition all 0.5s
          display block
          margin-left 5px
          width 20px
          height 20px
          top 10px
          right 8px
      .company
        margin-left 15px
        margin-top 10px
        align-self center
        li:nth-child(1)
          font-size 15px
          color #000000
          width 100%
          height 25px
          .icon-qiye
            color #F6B536
            display block
            float left
            width 4%
            margin-top 2px
          .icon-gengduo
            float right
            margin-right 10px
            display block
            width 20px
            height 20px
            transition all 0.5s
            float left
            margin-top 3px
          span
            display block
            margin-left 10px
            width 83%
            height 100%
            float left
            white-space nowrap
            text-overflow ellipsis
            overflow hidden
        li:nth-child(2)
          font-size 12px
          color #666666
          margin-top 8px
          span:nth-child(3)
            margin-left 10px
      .care
        li
          margin-left 15px
          width 90%
          margin-top 10px
        li:nth-child(1)
          span
            margin-right 10px
            overflow hidden
            text-overflow ellipsis
            white-space nowrap
            display inline-block
          span:nth-child(2)
            width 80%
          .box
            width 14px
            height 14px
            float right
            margin-top 1px
        li:nth-child(3)
          text-indent 40px
          color #999999
        .written
          width 95%
          height 110px
          border 1px solid #f2f2f2
          margin 0px auto
          margin-top 10px
          padding 10px
          box-sizing border-box
          textarea
            width 100%
            height 100% !important
            resize none
  .send
    position fixed
    width 100%
    height 49px
    display flex
    justify-content space-between
    bottom 0px
    font-size 16px
    color #ffffff
    li
      width 50%
      height 49px
      line-height 49px
      text-align center
    li:nth-child(1)
      background rgba(221, 221, 221, 1)
    li:nth-child(2)
      background rgba(75, 158, 251, 1)
  .case
    font-size 13px
    margin-left 15px
    p
      color #000000
      width 92%
      white-space nowrap
      overflow hidden
      text-overflow ellipsis
      margin-left 10px
    span
      color #4B9EFB
    li
      display flex
      margin-top 14px
  .open
    width 100%
    background-color #ffffff
    .open_case
      width 95%
      height 32px
      background rgba(238, 238, 238, 1)
      border-radius 3px
      margin 0px auto
      line-height 32px
      font-size 13px
      color #000000
      margin-top 5px
      div:nth-child(1)
        float left
        height 32px
        width 60%
        p:nth-child(1)
          color #4B9EFB
          line-height 32px
          height 32px
          display inline-block
          float left
          margin-left 5px
        p:nth-child(2)
          margin-left 10px
          width 82%
          white-space nowrap
          display inline-block
          overflow hidden
          text-overflow ellipsis
          display inline-block
          line-height 32px
          height 32px
          float left
      div:nth-child(2)
        width 40%
        float right
        height 32px
        line-height 32px
        p:nth-child(1)
          float left
          margin-left 5px
        p:nth-child(2)
          float left
          margin-left 5px
        i
          color #ffffff
          margin-left 5px
    .aggregate
      width 95%
      height 84px
      margin 0px auto
      border-bottom 1px solid #f2f2f2
      font-size 18px
      .left
        display flex
        justify-content space-between
        font-size 15px
        color #000000
        margin-top 10px
      .price
        display flex
        justify-content space-between
        text-align left
        margin-top 10px
        .give
          font-size 12px
          color #666666
        .give_three
          font-size 12px
          color #F01C1C
    .request
      width 95%
      min-height 118px
      margin 0px auto
      border-bottom 1px solid #f2f2f2
      font-size 15px
      .request_left
        display flex
        justify-content space-between
        margin-top 10px
      .request_right
        width 100%
        text-align left
        margin-top 10px
        color #666666
        li
          span:nth-child(2)
            margin-left 10px
    .book
      width 95%
      white-space pre
      flex-wrap wrap
      margin 20px auto 0px
      display flex
      justify-content space-between
      div
        padding-left 15px
        padding-right 15px
        text-align center
        line-height 25px
        font-size 14px
        color #333333
        font-family PingFangSC
        font-weight 400
        border-radius 13px
        background rgba(204, 204, 204, 1)
        box-shadow 0px 0px 3px 0px rgba(172, 197, 248, 1)
        margin-bottom 20px
</style>
