var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { staticClass: "views" },
        [
          _c("video", {
            ref: "video",
            staticStyle: { width: "100%", height: "calc(100vh - 22vh)" },
            attrs: {
              "x5-video-player-type": "h5",
              "webkit-playsinline": "true",
              "x-webkit-airplay": "true",
              "x5-video-orientation": "portraint",
              playsInline: "true",
              "x5-video-player-fullscreen": "portraint",
              "x5-video-ignore-metadata": "true",
              crossorigin: "anonymous",
              autoplay: "",
            },
          }),
          _c(
            "div",
            { staticClass: "picture", on: { click: _vm.pictureClick } },
            [_c("i", { staticClass: "iconfont icon-picture" })]
          ),
          _c("div", {
            staticClass: "action",
            on: { click: _vm.handlePhotographClick },
          }),
          _c("div", { staticClass: "folder", on: { click: _vm.folderClick } }, [
            _c("i", { staticClass: "iconfont icon-folder" }),
          ]),
          _c("div", { staticClass: "bac" }, [
            _c(
              "div",
              { staticStyle: { position: "absolute", bottom: "-25px" } },
              [
                _c(
                  "div",
                  {
                    ref: "scrollContainer",
                    staticStyle: {
                      "overflow-x": "scroll",
                      "white-space": "nowrap",
                      height: "22vh",
                      padding: "0 20px",
                      "margin-right": "2rem",
                    },
                  },
                  _vm._l(_vm.srcList, function (img, index) {
                    return _c(
                      "div",
                      { key: img.src + index, staticClass: "img_box" },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-guanbi",
                          on: {
                            click: function ($event) {
                              return _vm.delImg(img.name, index)
                            },
                          },
                        }),
                        img.name === "files"
                          ? _c("img", {
                              attrs: {
                                src: require("../../../../public/img/files1.png"),
                              },
                            })
                          : _c("img", { attrs: { src: img.src } }),
                        _c("span", [_vm._v(_vm._s(index + 1))]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _c("div", { staticStyle: { overflow: "hidden" } }, [
              _c("audio", { attrs: { preload: "auto" } }),
              _c("div", { staticClass: "btn", on: { click: _vm.upload } }, [
                _vm._v("确认"),
              ]),
            ]),
          ]),
          _c(
            "van-popup",
            {
              attrs: { "close-on-click-overlay": false },
              model: {
                value: _vm.lock,
                callback: function ($$v) {
                  _vm.lock = $$v
                },
                expression: "lock",
              },
            },
            [
              _c("div", { staticClass: "text" }, [
                _vm._v(" 上传中"),
                _c("br"),
                _vm._v("请勿退出"),
                _c("br"),
                _vm._v("以免上传失败！ "),
                _c("div", { staticClass: "loading" }, [
                  _c("div", { staticClass: "loading-img" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../../src/assets/loadings.jpg"),
                      },
                    }),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "van-popup",
            {
              attrs: { "close-on-click-overlay": false },
              model: {
                value: _vm.err,
                callback: function ($$v) {
                  _vm.err = $$v
                },
                expression: "err",
              },
            },
            [_c("div", { staticClass: "text red" }, [_vm._v("网络错误")])]
          ),
        ],
        1
      )
    : _c(
        "div",
        {
          staticStyle: {
            color: "skyblue",
            "font-size": "80px",
            "text-align": "center",
            "margin-top": "40vh",
          },
        },
        [_vm._v("上传成功")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }